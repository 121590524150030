function generateUniqueIdForThoughtObject(thoughtObj) {
  return thoughtObj.type + thoughtObj.label;
}

export default {
  getBooks(state) {
    return state.books;
  },

  getDict(state) {
    return state.dict;
  },

  getTranscriptText(state) {
    return state.transcriptText;
  },

  getPageContext(state) {
    return Object.values(state.pageTokens);
  },

  getSuggestData(state, val) {
    return state.g6_explore_data.nodes.filter((node) => node.id.includes(val));
  },

  getYoutubeData(state) {
    if (state.youtube_search_result) {
      const searchResults = state.youtube_search_result.items;
      // console.log(searchResults);
      const finalData = searchResults.map((item) => ({
        id: item.id,
        thumbnail: item.thumbnail.thumbnails !== undefined ? item.thumbnail.thumbnails[0].url : 'undefined url',
        title: item.title,
        runtime: item.length?.simpleText || 'undefined',
        uploaded_by: item.channelTitle,
        type: item.type,
        embed: `https://www.youtube.com/embed/${item.id}`,
        transcript: item.transcript,
      }));
      return finalData;
    }
    return [];
  },

  getQuests(state) {
    return state.all_quests;
  },
  getAvailableThoughtNodes(state) {
    return state.nav_data.nodes.filter((el) => el.tjtype === 'entity');
  },
  getCurrentEntityPositionInThoughtMap(state) {
    if (state.nav_data && state.nav_data.nodes && state.nav_data.nodes.length > 0) {
      return state.nav_data.nodes?.filter((el) => el.tjtype === 'entity')?.findIndex((el) => el.label === state.current_thought?.label);
    }
    return -1;
  },

  getWidgetsLinkedToEntityFromLabel(state) {
    const linkedWidgets = [];
    state.nav_data.edges.forEach((edge) => {
      if (edge.source === generateUniqueIdForThoughtObject(state.current_thought)) {
        linkedWidgets.push(edge.target);
      }
    });
    return state.nav_data.nodes.filter((el) => linkedWidgets.includes(el.id) && el.tjtype === 'widget');
  },
  compareWithCurrentThought(state) {
    return (thoughtObj) => {
      if (!state.current_thought) {
        return false;
      }
      const obj1Keys = Object.keys(thoughtObj);
      const obj2Keys = Object.keys(state.current_thought);
      if (obj1Keys.length !== obj2Keys.length) {
        return false;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const key of obj1Keys) {
        if (thoughtObj[key] !== state.current_thought[key]) return false;
      }
      return true;
    };
  },

  // eslint-disable-next-line no-underscore-dangle
  fetchNoteById: (state) => (noteId) => state.notes.data.filter((each) => each._id === noteId)[0],

  getAvailableTjTypes(state) {
    return state.g6_explore_data.nodes.filter((node) => node.tjType).map((node) => node.tjType);
  },
  getUserInfo(state) {
    return state.auth.user;
  },
  isLoggedIn(state) {
    return state.auth.status.isLoggedIn ? state.auth.status.isLoggedIn : false;
  },
};
