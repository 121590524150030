<template>
  <div @mouseup="fireUniversalEventMouseUp" id="app" ref="appcomp" class="">
    <!-- Default Layout Loader -->
    <DefaultLayout/>
    <ed-tooltip
      :wordMeaningPopup="wordMeaningPopup"
      :toggleWordMeaningPopup="toggleWordMeaningPopup"
    />

    <QuickSearch/>
  </div>
</template>

<script>
// import io from 'socket.io-client';
import hotkeys from 'hotkeys-js';
import { mapGetters } from 'vuex';
import hotKeyMappings from '@/config/hotKeyMappings';
import QuickSearch from '@/common/components/QuickSearch.vue';
import universalMappings from '@/config/universalMapping';
import 'boxicons';
import 'mavon-editor/dist/css/index.css';
import exploreOps from '@/common/widgets/GraphExploreWidget/exploreOps';
import edTooltip from '@/utilities/ed-tooltip.vue';
import graphShortcut from '@/common/core/Graph/graphShortcut';
import smartQueryMode from '@/common/core/Graph/query/smartQueryMode';
import zohoUtils from '@/config/zohoConfig';
import DefaultLayout from './layouts/DefaultLayout.vue';

export default {
  name: 'app',

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Thoughtjumper',
    // all titles will be injected into this template
    titleTemplate: '%s | Thoughtjumper',
  },

  components: {
    DefaultLayout,
    QuickSearch,
    edTooltip,
  },

  data() {
    return {
      isNewNotification: false,
      message: 'sasds',
      wordMeaningPopup: {
        title: '',
        info: '',
        isActive: false,
      },
      hotKeyMappings,
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),
    sidebarToggleStatus() {
      return this.$store.state.uiStates.sidebarToggleStatus;
    },

    getCameraStatus() {
      return this.$store.state.cameraStatus;
    },
    username() {
      return this.user.username;
    },

    isGameFrame() {
      return this.$route.path.includes('GameFrame');
    },
  },

  mounted() {
    window.parent.postMessage('{"data":{"mounted": true}}', 'https://signalschool.edvantalabs.com');
    window.onmessage = (event) => {
      if (event.origin === 'https://signalschool.edvantalabs.com') {
        console.log('xvf', event.data.data);
        const response = event.data.data;
        console.log('will parse token here', response.userToken);
        if (response.type === 'auth' && response.status === 200) {
          localStorage.setItem('userToken', response.userToken);
          localStorage.setItem('refreshToken', response.refreshToken);
          localStorage.setItem('font-size', 50);
          // setTimeout(() => {
          //   this.$router.push({
          //     name: 'Home',
          //   });
          // }, 1000);
        }
      }

      // school of unlearn signal
      // console.log('xvf url matchin', event.origin, process.env.VUE_APP_GAME_URI);
      if (event.origin === process.env.VUE_APP_GAME_URI) {
        const response = event.data;
        // console.log('xvf', 'game-connected', 'deep-dive', JSON.parse(response).value);
        this.$store.dispatch('game/toggleConnected', JSON.parse(response));
      }
    };
    this.$store.dispatch('setGlobalFontSize', { fetchFromLocal: false, _fontSize: 75 });
    console.log('FrameBed APp.vue', this.$store.state.routeInfo);
    this.$refs.appcomp.addEventListener('runCode', (e) => {
      const globalOperations = smartQueryMode(e.detail.trim());

      if (globalOperations.createList !== undefined) {
        exploreOps.queryOperation(globalOperations.createList);
        this.$store.dispatch('gmodule/saveG6ExploreData');
      }
    });

    this.$refs.appcomp.addEventListener('runNode', (e) => {
      this.$store.dispatch('focusCurrentItem', {
        id: e.detail.id,
        type: 'node',
        state: {
          isActiveDetail: 'Nodes', //! Its not a good way but doing it just to make it work
        },
      });
    });

    // console.log('ROUTE BLOCKING', window.innerWidth);
    // if (window.innerWidth <= 768) {
    //   this.$router.push({ name: 'MobileView' });
    //   this.$store.dispatch('layouts/toggleChangeView', 'mobileView');
    // } else {
    //   this.$router.push({ name: 'Home' });
    //   this.$store.dispatch('layouts/toggleChangeView', 'exploreView');
    // }

    // window.addEventListener('resize', (e) => {
    //   if (e.target.innerWidth <= 768) {
    //     this.$router.push({ name: 'MobileView' });
    //     this.$store.dispatch('layouts/toggleChangeView', 'mobileView');
    //   } else {
    //     this.$router.push({ name: 'Home' });
    //     this.$store.dispatch('layouts/toggleChangeView', 'exploreView');
    //   }
    // });
    graphShortcut();
    this.hotKeyFunctionGenerator(hotKeyMappings);
    // hotkeys('alt+shift+r', () => this.hotkeyPressedstartScreenRecord());
    hotkeys('shift+e', () => this.hotKeyPressedSwitchRoute('/'));
    hotkeys('alt+shift+1', () => this.chooseMode('looked_at'));
    hotkeys('shift+a', () => this.hotKeyPressedSwitchRoute('/Author-view'));
    hotkeys('shift+s', () => this.hotKeyPressedSwitchRoute('/Simplified-view'));
    hotkeys('shift+r', () => this.hotKeyPressedSwitchRoute('/Reading-view'));
    hotkeys('shift+c', () => this.hotKeyPressedSwitchRoute('/canvas'));
    universalMappings.forEach((mapping) => {
      hotkeys(mapping.hotKey, () => this.chooseMode(mapping.mode_name));
    });
    window.addEventListener('scroll', this.handleScroll, true);
  },

  methods: {
    addZohoChatbot() {
      setTimeout(() => {
        const newScript = document.createElement('script');
        newScript.setAttribute('type', 'text/javascript');
        newScript.id = 'zsiqchat';
        const alert = document.createTextNode(
          zohoUtils.textNode,
        );
        newScript.appendChild(alert);
        document.body.appendChild(newScript);
      }, 2000);
    },
    chooseMode(modename) {
      this.$store.dispatch('gmodule/setEditMode', modename);
    },
    activeLayoutPayload(componentName) {
      const payload = {
        layoutKey: this.$store.state.activeLayoutKey,
        componentName,
      };
      return payload;
    },
    hotKeyFunctionGenerator(mapping) {
      mapping.forEach((keyMap) => {
        hotkeys(keyMap.key, () => this.hotKeyPressedSwitchComponent(keyMap.mapping));
      });
    },
    hotKeyPressedSwitchComponent(componentName) {
      this.$store.dispatch(
        'activateLayout',
        this.activeLayoutPayload(componentName.trim()),
      );
    },
    hotKeyPressedSwitchRoute(route) {
      this.$router.push({ path: route });
    },
    hotkeyPressedstartScreenRecord() {
      this.$root.$emit('startScreenRecord');
    },
    countWords(str) {
      let strWord = str.replace(/(^\s*)|(\s*$)/gi, '');
      strWord = strWord.replace(/[ ]{2,}/gi, ' ');
      strWord = strWord.replace(/\n /, '\n');
      return strWord.split(' ').length;
    },

    async fireUniversalEventMouseUp(e) {
      if (e.ctrlKey || e.metaKey) {
        const select = window.getSelection().toString();
        // Regex to calculate the Word Cound
        const wordCount = this.countWords(select);

        // if (wordCount <= 4 && wordCount > 0) {
        if (wordCount === 1) {
          const oneSearch = select.replace(/\s/g, '');
          if (oneSearch.length > 1) {
            const wordObj = {
              word: oneSearch,
              label: oneSearch,
              type: 'string',
              lang: this.$store.state.locale.currentLocale,
            };
              // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
            await this.$store.dispatch('setCurrentThought', { thoughtObject: wordObj });
            this.showModal = true;
          }
        } else {
          const wordObj = {
            word: select,
            label: select,
            type: 'string',
            lang: this.$store.state.locale.currentLocale,
          };
            // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
          this.$store.dispatch('setCurrentThought', { thoughtObject: wordObj });
        }
        // }
      } else if (e.altKey || e.metaKey) {
        const select = window.getSelection().toString();
        const oneSearch = select.replace(/\s/g, '');
        const url = await this.$store.dispatch('dictWordPopUp', oneSearch);

        const title = url.result[0].word;
        const info = url.result[0].meanings[0].definitions[0].definition;
        this.wordMeaningPopup.title = title;
        this.wordMeaningPopup.info = info;
        this.toggleWordMeaningPopup('switch');
      }
    },
    fireUniversalEvent() {
      if (this.$store.state.gmodule.edit_mode !== 'looked_at') {
        const select = window.getSelection().toString();
        // Regex to calculate the Word Cound
        const wordCount = this.countWords(select);

        if (wordCount <= 4 && wordCount > 0) {
          if (wordCount === 1) {
            const oneSearch = select.replace(/\s/g, '');
            if (oneSearch.length > 1) {
              const wordObj = {
                word: oneSearch,
                label: oneSearch,
                type: 'string',
                lang: this.$store.state.locale.currentLocale,
              };
              // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
              this.$store.dispatch('setCurrentThought', { thoughtObject: wordObj });
            }
          } else {
            const wordObj = {
              word: select,
              label: select,
              type: 'string',
              lang: this.$store.state.locale.currentLocale,
            };
            // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
            this.$store.dispatch('setCurrentThought', { thoughtObject: wordObj });
          }
        }
      }
    },
    handleScroll(e) {
      if (e.target.classList.contains('on-scrollbar') === false) {
        e.target.classList.add('on-scrollbar');
        setTimeout(() => {
          e.target.classList.remove('on-scrollbar');
        }, 2000);
      }
    },
    toggleWordMeaningPopup(opt = 'switch') {
      if (opt === 'close') {
        this.wordMeaningPopup.isActive = false;
        this.wordMeaningPopup.title = '';
        this.wordMeaningPopup.info = '';
      } else {
        this.wordMeaningPopup.isActive = !this.wordMeaningPopup.isActive;
      }
    },
  },

  sockets: {
    // ! WARNING ::::::::: If multiple instance is opened of same user then these dispatcher will get triggered that many time, will fix this later
    newNotification(data) {
      // this piece of code is responsible for add_to_search functionality of Socket
      if (data.verb.display.en === 'SEARCHED_PING') {
        if (this.$store.state.auth.user.username === data.actor.username) {
          const wordObj = {
            word: data.value.word,
            label: data.value.word,
            type: 'string',
            lang: 'en',
          };
          this.$store.dispatch('setCurrentThought', { thoughtObject: wordObj });
        }
      } else if (data.verb.display.en === 'THOUGHT_ADDED') {
        if (this.$store.state.auth.user.username === data.actor.username) {
          this.$store.dispatch('addNoteAndGraph', data.value);
        }
      } else if (data.verb.display.en === 'THOUGHT_LINKED') {
        if (this.$store.state.auth.user.username === data.actor.username) {
          this.$store.dispatch('linkNoteToCurrentActivity', data.value);
        }
      }
    },
  },
  async created() {
    // ! TODO - needs to refactor
    await this.$highway.singleDispatcher('gmodule/exploreGraphFetch');
  },
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

</style>
