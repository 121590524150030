<template>
  <div class="sidebar-toggle" title="Sidebar" :class="isSidebarOpen ? 'sidebar-toggle__active' : 'sidebar__inactive'">
    <i
      class="sidebar-toggle-btn d-flex bx bx-menu-alt-left"
      title="Sidebar Button"
      @click="openSidebar()"
    ></i>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SidebarToggle',
  props: {
    // imageUrl: {
    //   type: String,
    //   default:
    //     'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png',
    // },
    username: {
      type: String,
      default: 'NA',
    },
    email: {
      type: String,
      default: 'NA',
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSidebarOpen: false,
    };
  },

  mounted() {
    this.fetchPoints();
    this.$root.$on('sidebar', this.openSidebar);
  },
  beforeDestroy() {
    this.$root.$off('sidebar');
  },
  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),
    userPoints() {
      const points = this.$store.getters['points/getPoints'];
      console.log('points', points);
      return points;
    },
    imageUrl() {
      return this.user?.profileImage.Location;
    },
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout',
      pointsAction: 'points/setPoints',
    }),
    openSidebar() {
      this.$store.dispatch('toggleSidebar', true);
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.fetchPoints();
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    editProfile() {
      this.$router.push({ name: 'edit-profile' });
    },
    logout() {
      this.logoutAction().then(() => {
        this.$router.push({ name: 'Auth', params: { action: 'Login' } });
      });
    },
    async fetchPoints() {
      const data = await this.pointsAction(this.username);
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>

.user-profile__img{
  width: 3.2rem;
  margin-left: 1rem;
}
</style>
