/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

const uniqueIdGenerate = (str) => {
  const id = str + Math.random().toString(36).substr(2, 9);
  return id;
};

// const tokenizer = {
//   code(src) {
//     // one line string
//     // eslint-disable-next-line
//     // js to from regexp syntax
//     const re = /\$ed(.*?)ed\$/g;
//     const match = src.match(/^\$ed+([^\$\n]+?)ed\$+/);
//
//     if (match) {
//       if (match[0].startsWith('$ed')) {
//         return {
//           type: 'code',
//           raw: match[0],
//           text: match[1].trim(),
//         };
//       }
//       return {
//         type: 'code',
//         raw: match[0],
//         text: match[1].trim(),
//       };
//     }

//     // return false to use original codespan tokenizer
//     return false;
//   },
// };

const codeList = {
  name: 'codeList',
  level: 'block', // Is this a block-level or inline-level tokenizer?
  start(src) {
    return src;
  }, // Hint to Marked.js to stop and check for a match
  // eslint-disable-next-line
  tokenizer(src, tokens) {
    // eslint-disable-next-line
    const rule = /^\$ed+([^$]+?)\ed\$+/; // Regex for the complete token, anchor to string start
    const match = rule.exec(src);
    if (match) {
      const token = {
        // Token to generate
        type: 'codeList', // Should match "name" above
        raw: match[0], // Text to consume from the source
        text: match[0].trim(), // Additional custom properties
        tokens: [], // Array where child inline tokens will be generated
      };
      return token;
    }
  },
  renderer(token) {
    return `<div>
      <code>${token.raw}\n</code>
      <button onclick="SendAllThistoNotes(event)"> Run </button>
     </div>
    `; // parseInline to turn child tokens into HTML
  },
};

const nodeLink = {
  name: 'nl',
  level: 'block', // Is this a block-level or inline-level tokenizer?
  start(src) {
    return src;
  }, // Hint to Marked.js to stop and check for a match
  // eslint-disable-next-line
  tokenizer(src, tokens) {
    // eslint-disable-next-line
    const rule = /^\$nl+([^$]+?)nl\$+/; // Regex for the complete token, anchor to string start
    const match = rule.exec(src);
    if (match) {
      const token = {
        // Token to generate
        type: 'nl', // Should match "name" above
        raw: match[0], // Text to consume from the source
        text: match[0].trim(), // Additional custom properties
        tokens: [], // Array where child inline tokens will be generated
      };
      return token;
    }
  },
  renderer(token) {
    const elData = token.raw.replace('$nl', '').replace('nl$', '').trim();
    const label = elData.split(']')[0].replace('[', ''); // [game](id)
    const id = elData.split('(')[1].replace(')', ''); // [game](id)
    return `<div>
      <p onclick="nodeFocusFromNote(event)" nodeid="${id}" style="cursor:pointer;margin:0;padding:0;color:#659dbd;">${label}</p>
     </div>
    `; // parseInline to turn child tokens into HTML
  },
};

const underlineExtension = {
  name: 'underline',
  level: 'inline', // This is an inline-level tokenizer
  start(src) {
    return src.indexOf('++');
  },
  // eslint-disable-next-line
  tokenizer(src, tokens) {
    const rule = /^\+\+(.+?)\+\+/; // Regex for the complete token, anchor to string start
    const match = rule.exec(src);
    if (match) {
      const token = {
        type: 'underline', // Should match "name" above
        raw: match[0], // Text to consume from the source
        text: match[1], // Additional custom properties
      };
      return token;
    }
  },
  renderer(token) {
    return `<u>${token.text}</u>`;
  },
};

// const jsonFormatter = {
//   name: 'jsonFormatter',
//   level: 'block',
//   start(src) {
//     return src.indexOf('{');
//   },
//   // eslint-disable-next-line
//   tokenizer(src, tokens) {
//     const rule = /^\{[\s\S]*\}$/gm; // Regex to match JSON blocks
//     const match = rule.exec(src);
//     if (match) {
//       try {
//         const json = JSON.parse(match[0]);
//         const formattedJson = JSON.stringify(json, null, 2);
//         const token = {
//           type: 'jsonFormatter',
//           raw: match[0],
//           text: formattedJson,
//         };
//         return token;
//       } catch (e) {
//         // If parsing fails, return false to use original codespan tokenizer
//         return false;
//       }
//     }
//   },
//   renderer(token) {
//     const content = `<pre><code class="json">${token.text}</code></pre>`;
//     const style = `
//       <style>
//         code.json {
//           white-space: pre-wrap; /* CSS to handle line breaks */
//           word-wrap: break-word; /* Break long words */
//         }
//       </style>
//     `;
//     return `${style}${content}`;
//   },
// };

const jsonFormatter = {
  name: 'jsonFormatter',
  level: 'block',
  start(src) {
    return src.indexOf('{');
  },
  // eslint-disable-next-line
  tokenizer(src, tokens) {
    const rule = /^\{[\s\S]*\}$/gm;
    const match = rule.exec(src);
    if (match) {
      try {
        const json = JSON.parse(match[0]);
        const formattedJson = JSON.stringify(json, null, 2);
        const token = {
          type: 'jsonFormatter',
          raw: match[0],
          text: formattedJson,
        };
        return token;
      } catch (e) {
        return false;
      }
    }
  },
  renderer(token) {
    const truncatedContent = `<pre><code class="json">${token.text.substring(0, 500)}...</code></pre>`;
    const fullContent = `<pre><code class="json">${token.text}</code></pre>`;
    const style = `
      <style>
        .json-container code {
          white-space: pre-wrap; /* CSS to handle line breaks */
          word-wrap: break-word; /* Break long words */
          display: block;
          overflow-wrap: break-word; /* Ensures proper word breaking */
          background: #f7f7f7;
          padding: 10px;
          border-radius: 5px;
        }
        .json-container {
          margin: 10px 0;
        }
        .show-more {
          color: #007bff;
          cursor: pointer;
        }
        .hidden {
          display: none;
        }
      </style>
    `;
    const id = `json-content-${Math.random().toString(36).substr(2, 9)}`;
    return `${style}
      <div class="json-container">
        <div id="${id}--half" data-content="${id}--half">${truncatedContent}</div>
        <div id="${id}--full" data-content="${id}--full" class="hidden">${fullContent}</div>
        <div class="show-more" onclick="document.toggleJsonContent(event, '${id}')">Show more</div>
      </div>`;
  },
};

module.exports = {
  codeList,
  nodeLink,
  underlineExtension,
  jsonFormatter,
};
