/* eslint-disable */
export default {
  // Auth Mutations End
  setBooks(state, books) {
    state.books = books;
  },

  SET_ARCHIVE_DATA: (state, data) => {
    state.archivedData = data;
  },

  SET_TRANSCRIPT_DATA(state, payload) {
    state.transcription = payload
  },

  SET_AUDIO_DATA(state, payload) {
    state.audio = payload
  },

  setWidgetName(state, widgetName) {
    state.widgetName = widgetName;
  },

  UPDATE_NODE_INFO_SECTION: (state, section) => {
    state.node_info_section = section
  },

  setWikiWord: (state, wikiWord) => {
    state.wikiWord = wikiWord;
  },

  setGraph: (state, graph) => {
    state.graph.nodes = Object.freeze(graph.nodes);
    state.graph.links = Object.freeze(graph.links);
  },

  SET_EDDIE_HISTORY: (state, history) => {
    state.eddie_history = history;
  },

  IS_SEARCHING_FROM_OPENAI: (state, data) => {
    state.openai_meta.is_searching = data;
  },
  TOGGLE_SHOW_HISTORY_FROM_OPENAI: (state) => {
    state.openai_meta.showHistory = !state.openai_meta.showHistory;
  },

  UPDATE_ANSWER: (state, answer) => {
    state.eddie_history.data = state.eddie_history.data.map(i => {
      if (answer.id === i.id) {
        return answer;
      }
      return i;
    })
  },

  SET_OPENAI_RESULT: (state, result) => {
    state.eddie_result.response += result.chunk;
  },

  EMPTY_OPENAI_RESULT: (state) => {
    state.eddie_result.response = '';
  },

  ADD_NEW_EVENT: (state, payload) => {
    state.eventsRaw.unshift(payload);
  },

  setVisitedGraph: (state, graph) => {
    state.visited_graph.nodes = Object.freeze(graph.nodes);
    state.visited_graph.links = Object.freeze(graph.links);
  },

  setWikiInformation: (state, wikiInformation) => {
    state.wikiInformation = wikiInformation;
  },

  setExploreInformation: (state, exploreObj) => {
    state.explore = exploreObj;
  },

  SET_AUTOCOMPLETE_SUGGESTIONS: (state, suggestions) => {
    state.autocompleteSuggestionData = suggestions;
  },

  SET_ACTIVATE_LAYOUT: (state, newLayout) => {
    state.layout = newLayout;
  },

  setRangeValue: (state, rangeValue) => {
    state.explore_data_range = rangeValue;
  },

  setEdgeValue: (state, edgeValue) => {
    state.isEdgeMap = edgeValue;
  },

  setVerb: (state, verb) => {
    state.verb = verb;
  },

  toggleSidebar: (state) => {
    state.uiStates.sidebarToggleStatus = !state.uiStates.sidebarToggleStatus;
  },

  SET_FG_COMPONENT_SETTING: (state, graphSettings) => {
    state.graph.setting.nodeFilter = graphSettings.nodeFilter;
    state.graph.setting.edgeFilter = graphSettings.edgeFilter;
  },

  SET_SELECTED_NODE: (state, selectedNode) => {
    state.selectedNode = selectedNode;
  },

  CLEAR_META_SELECTION: (state) => {
    state.selectedNode = null;
    state.wikiInformation = null;
    state.wolframInformation = null;
  },

  ASK_WOLFRAM: (state, data) => {
    state.wolframInformation = {
      question: data.question,
      answer: data.answer,
    };
  },

  SHOW_NOTIFICATION: (state) => {
    state.uiStates.notificationToggleStatus = true;
  },

  HIDE_NOTIFICATION: (state) => {
    state.uiStates.notificationToggleStatus = false;
  },

  singleNote: (state, note) => {
    state.singleNote = note;
  },

  SET_TAG_CONTENT: (state, content) => {
    state.tagContent = content;
  },

  SET_EVENTS_RAW_LOG: (state, eventsRaw) => {
    state.eventsRaw = eventsRaw.data;
  },

  SET_GRAPH_EVENTS: (state, eventsData) => {
    state.eventsData = eventsData;
  },

  SET_EVENTS_VERB: (state, eventsRaw) => {
    state.eventsVerb = eventsRaw.data;
  },
  SET_NOTES: (state, eventsRaw) => {
    state.notes = eventsRaw.data;
  },
  START_LOADER: (state, loaderInfo) => {
    state.loaderFeed[loaderInfo.id] = loaderInfo.payload;
  },

  SET_ROUTE_INFO: (state, routeInfo) => {
    state.routeInfo = routeInfo;
  },

  UPDATE_TREE: (state, tree) => {
    state.tree = tree;
  },

  UPDATE_NOTIFICATION_COUNT: (state) => {
    state.notificationCount += 1;
  },

  UPDATE_COMPONENTKEY: (state) => {
    state.componentKey += 1;
  },

  END_LOADER: (state, id) => {
    delete state.loaderFeed[id];
  },
  SET_GRAPH_DATA: ({ state, commit }, graphData) => {
    state.g6_graph_data = graphData;
  },
  SET_SAVED_TJ_STATE: (state, saved) => {
    state.uiStates = saved.uiStates;
    state.wikiInformation = saved.wikiInformation;
    state.currentBook = saved.currentBook;
    state.explore = saved.explore;
    state.selectedNode = saved.selectedNode;
    state.graph.setting = saved.graph.setting;
    state.wolframInformation = saved.wolframInformation;
    state.layout = saved.layout;
  },

  SET_CURRENT_BOOK: (state, book) => {
    state.currentBook = book;
  },
  CLEAR_CURRENT_BOOK: (state) => {
    state.currentBook = null;
  },

  SET_MUTATIONSERIES: (state, mutationSeries) => {
    state.mutationSeries = mutationSeries;
  },

  SET_WIDGET_SETTINGS: (state, widgetSettings) => {
    state.settings.widget = widgetSettings;
  },
  SET_SOCIAL_SETTINGS: (state, socialSettings) => {
    state.settings.social = socialSettings;
  },

  SET_THREEDMODELDATA: (state, data) => {
    state.threeDModelData = data;
  },

  SET_SUGGESTIONDATA: (state, data) => {
    state.suggestionData = data;
  },

  SET_GUT_BOOKS: (state, data) => {
    state.gutenbergBooks = data;
  },

  SET_GUT_BOOK: (state, data) => {
    state.gutenbergBook = data;
  },

  DELETE_NOTE: (state, note) => {
    state.notes.splice(state.eventsVerb.indexOf(note), 1);
  },

  SET_WIKI_SNIPPET: (state, data) => {
    state.wikiSnippet = data;
  },

  SET_LAYOUT_SETTINGS: (state, layoutSettings) => {
    state.layout.layout_tabs = layoutSettings.layout;
    state.layout.layout_tab_selection = layoutSettings.layout_selection;
  },

  SET_NODE_LABEL: (state, data) => {
    state.newNodeLabel = data;
  },

  SET_NODE_TYPE: (state, data) => {
    state.newNodeType = data;
  },

  SET_EDGE_LABEL: (state, data) => {
    state.newEdgeLabel = data;
  },

  SET_EDGE_STRENGTH: (state, data) => {
    state.edge_strength = data;
  },

  SET_LOCALE: (state, locale) => {
    state.locale.currentLocale = locale;
  },
  setActiveLayoutKeyMut: (state, key) => {
    state.activeLayoutKey = key;
  },
  SET_COMPARE_DETAIL: (state, data) => {
    state.compareDetails = data;
  },
  SET_MOST_SEARCHED_WORDS: (state, data) => {
    state.mostSearchedWords = data;
  },
  SET_UPLOADING_G6_DATA: (state) => {
    state.is_uploading_data = !state.is_uploading_data;
  },
  SET_QUEST_UPLOADING: (state) => {
    state.isQuestUploading = !state.isQuestUploading;
  },
  SET_COMPARE_LIST: (state, data) => {
    state.compareList = data;
  },

  setExploreGraphData: (state, data) => {
    state.g6_explore_data = data;
  },

  SET_LIBRARY_MODE_QUEST: (state, data) => {
    state.library_mode_quest = data
  },

  SET_EXPLORE_DESCRIPTION: (state, payload) => {
    state.explore_description = payload;
  },

  SET_PIE_DATA: (state, data) => {
    state.pie_data = data;
  },

  SET_PAYLOAD_DETAIL: (state, data) => {
    state.payload_detail = { ...data };
  },

  SET_EXCLUDE_DISPATCHER: (state, data) => {
    state.excludeCallList = data;
  },

  SET_DETAIL_DESCRIPTION: (state, payload) => {
    state.detail_description = payload;
  },

  setEricRecords: (state, data) => {
    state.eric_records = data;
  },

  SET_GRAPH_EXPANSION: (state, data) => {
    const allNodes = [...state.g6_graph_data.nodes, ...data.nodes];
    const newData = {};

    const uniqueNodes = allNodes.reduce((uniqueMap, node) => {
      if (uniqueMap[node.id] === undefined) {
        // eslint-disable-next-line no-param-reassign
        uniqueMap[node.id] = node;
      }
      return uniqueMap;
    }, {});
    newData.nodes = Object.values(uniqueNodes);

    newData.edges = [...state.g6_graph_data.edges, ...data.edges];
    state.g6_graph_data = newData;
    // const sourceNodes = state.g6_graph_data.edges.map(item => { return item.target });
    // const occurrences = sourceNodes.reduce(function (acc, curr) {
    //   if (acc[curr]) {
    //     acc[curr].count += acc[curr].count;
    //   } else {
    //     acc[curr] = {
    //       id: curr,
    //       count: 1
    //     }
    //   }
    //   return acc
    // }, {});

    // Object.keys(occurrences).map(key => {
    //   if (occurrences[key].count > 1) {
    //     state.g6_graph_data.nodes.map(item => {
    //       if (item.id === key) {
    //         item.style = {
    //           fill: '#0000FF',
    //           stroke: '#888',
    //         }
    //       }
    //     });
    //   }
    // });
  },

  FETCH_AUTO_MARKDOWN: (state, data) => {
    state.currentMarkdown = data;
  },

  SET_CURRENT_ENTITY: (state, data) => {
    state.current_selected_entity = data;
  },
  SET_CURRENT_THOUGHT: (state, data) => {
    state.current_thought = data;
  },

  SET_EDIT_MODE: (state, data) => {
    state.edit_mode = data;
  },
  SET_SAVED_GRAPH: (state, graph) => {
    state.g6_graph_saved = graph;
    state.active_graph_id[graph.graph_dimension] = graph._id;
  },

  TOGGLE_GRAPH_LIBRARY_VIEW: (state, data) => {
    state.graph_library_view = data;
  },

  TOGGLE_CAMERA_STATUS: (state) => {
    state.cameraStatus = !state.cameraStatus;
  },

  SET_SCREEN_RECORD_STATE: (state, data) => {
    state.screenRecorder = data;
  },

  SET_SCREEN_RECORD_CAPTION: (state, data) => {
    state.screenRecordCaption = data;
  },

  EMPTY_GRAPH: (state) => {
    state.g6_explore_data = { nodes: [], edges: [] };
  },

  SET_ALL_GRAPH_LIST: (state, data) => {
    state.saved_graph_list = data;
  },

  ADD_NEW_SAVED_GRAPH: (state, data) => {
    state.saved_graph_list.data.unshift(data)
  },

  UPDATE_GRAPH_LIBRARY: (state, data) => {
    state.saved_graph_list.data = state.saved_graph_list.data.map((item) => {
      if (data.id === item.id) {
        return {
          _id: data.id,
          id: data.id,
          g6_data: {
            graph_name: data.g6_data.graph_name,
            graph_type: data.g6_data.graph_type,
          },
          timestamp: data.timestamp,
          actor: data.actor,
          tags: data.tags,
          access: data.access,
          sharedAccess: data.sharedAccess,
          graph_dimension: data.graph_dimension,
          metadata: data.metadata
        };
      } else {
        return item;
      }
    })
  },

  UPDATE_GRAPH_LIBRARY_PROPERTY: (state, data) => {
    state.saved_graph_list.data = state.saved_graph_list.data.map((item) => {
      if (data.id === item.id) {
        return {
          ...item,
          ...data.payload
        };
      } else {
        return item;
      }
    })
  },

  FILTER_GRAPH_LIBRARY: (state, id) => {
    state.saved_graph_list.data = state.saved_graph_list.data.filter((item) => {
      return item.id !== id
    })
  },

  ADD_NOTE_IN_LIBRARY: (state, data) => {
    state.notes.data.unshift(data)
  },

  DELETE_NOTE_FROM_NOTES_LIBRARY: (state, id) => {
    state.notes.data = state.notes.data.filter((item) => {
      return item._id !== id
    })
  },

  // DELETE_NOTE_FROM_LIBRARY: (state, data) => {
  //   state.delete.data = data
  // },

  UPDATE_NOTE_IN_LIBRARY: (state, data) => {
    state.notes.data = state.notes.data.map((item) => {
      if (data._id === item._id) {
        return data;
      } else {
        return item;
      }
    })
  },

  SET_ACTIVE_GRAPH_ID: (state, graph) => {
    if (graph) {
      state.active_graph_id[graph.graph_dimension] = graph._id;
    } else {
      state.active_graph_id = {
        explore_library: '',
        quest_library: '',
      }
    }
  },

  EMPTY_GRAPH_ID: (state, graph_type) => {
    state.active_graph_id[graph_type] = ''
  },

  SET_ALL_QUESTS: (state, data) => {
    state.all_quests = data;
  },
  SET_CURRENT_QUEST: (state, data) => {
    state.current_quest_id = data;
  },
  UPDATE_NAV_GRAPH: (state, data) => {
    state.nav_data.nodes = [...state.nav_data?.nodes, ...data.nodes];
    state.nav_data.edges = [...state.nav_data?.edges, ...data.edges];
    state.nav_data = {
      nodes: state.nav_data.nodes,
      edges: state.nav_data.edges,
    };
  },
  SET_NAV_DATA: (state, data) => {
    state.nav_data = data;
  },

  CLEAR_THOUGHT_MAP: (state) => {
    state.nav_data = {
      nodes: [],
      edges: [],
    };
  },

  SET_THOUGHT_MAP_RECORDING_STATE: (state, data) => {
    state.isThoughtMapRecording = data;
  },

  SET_LAST_THOUGHT: (state, data) => {
    state.last_thought = data;
  },

  SET_LAST_SELECTED_ENTITY: (state, data) => {
    state.last_selected_entity = data;
  },

  SET_TEXT_TO_SPEECH: (state, data) => {
    state.text_to_speech = data;
  },

  SET_DESCRIPTION_MODE: (state, data) => {
    state.explore_description_settings.selectedMode = data;
  },

  SET_YOUTUBE_SEARCH_RESULT: (state, data) => {
    state.youtube_search_result = data;
  },
  UPDATE_YOUTUBE_SEARCH_RESULT: (state, data) => {
    const newItems = { ...data, items: [...state.youtube_search_result.items, ...data.items] };
    state.youtube_search_result = newItems;
    
  },
  SET_GRAPH_PROPERTIES: (state, data) => {
    if (data.property === 'layoutType') {
      state.graphConfigs[data.property] = data.value;
    } else {
      state.graphConfigs[data.layout][data.property] = data.value;
    }
  },
  TOGGLE_QUEST_MODE: (state) => {
    state.questMode = !state.questMode;
  },
  SET_USER_ANALYTICS: (state, payload) => {
    state.userAnalytics = payload;
  },
  EDITOR_AI_STORE: (state, payload) => {

    const capitalizedFirstLetter = payload.payload.charAt(0).toUpperCase();
    const restOfTheString = payload.payload.slice(1);
    const capitalizedPayload = capitalizedFirstLetter + restOfTheString;

    state.editorAIFetch = `**${capitalizedPayload}?**\n\n${payload.value} \n\n ---- \n\n`;
  },

  NOTIFICATION_STORE: (state, payload) => {
    state.feedbackNotification = payload
  },
  clearFeedbackNotification(state) {
    state.feedbackNotification = null;
  },
  TOGGLE_HSL_STATE: (state, payload) => {
    state.hslState = payload;
  },

};
