import axios from 'axios';

export default {
  async fetchMessagesUsingThreadId(_, payload) {
    const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/eddie/smart-messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  },

  async fetchSmartCharts(_, payload) {
    try {
      const charts = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/eddie/smart-charts/${payload.threadId}`);
      return charts.data.charts;
    } catch (error) {
      console.log('xvf', 'error', error);
      return [];
    }
  },

  async streamResponseWithAssistantAndThreadId(_, {
    imageInput, userPrompt, threadId, assistantId, callback,
  }) {
    try {
      const formData = new FormData();

      // Append the image file to formData. Replace `imageFile` with the actual file object.
      if (imageInput) {
        formData.append('openai_file_upload', imageInput);
      }

      // Append other parts of the data as individual form fields
      formData.append('userPrompt', userPrompt);
      formData.append('assistantId', assistantId);
      formData.append('threadId', threadId);
      const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/eddie/smart-run`, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: formData,
      });

      if (!response.body) {
        throw new Error('ReadableStream not yet supported in this browser.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let result = '';
      const isRun = true;
      while (isRun) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        result += chunk;
        callback(chunk);
      }

      console.log(result);
      console.log('Stream ended.');
      return result;
    } catch (error) {
      console.error('Error:', error);
    }

    return '';
  },

  async fetchBrains({ commit }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/brains`);
      commit('SET_BRAINS', response.data);
    } catch (error) {
      console.error('Error fetching brains:', error);
      // You can commit an error mutation or handle the error as needed
    }
  },

  async toggleBrainAction({ dispatch }, { action, vectorStoreId }) {
    try {
      const response = await axios.put(`${process.env.VUE_APP_AXIOS_URI}/userProfiles/brains/${action}`, {
        vectorStoreId,
      });

      if (response.status === 200) {
        dispatch('auth/updateConnectedBrains', response.data.brains, { root: true });
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('xvf', error);
      return null;
    }
  },

  async fetchBrainFiles({ commit, state }, { vectorStoreId, brainIndex }) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/eddie/vector-store/files-count`, {
        id: vectorStoreId,
      });

      const files = response.data.file || [];
      const brains = [...state.brains];
      brains[brainIndex].files = files;
      console.log('xvf', 'there is a brain', brains);
      commit('SET_BRAINS', brains);
      // if (response.status === 200) {
      // }
    } catch (error) {
      console.error('Error Fetching  Suggestions', error);
    }
  },

  async generateTestOnTheBasisOfSubtopicAndAssessment({ commit }, payload) {
    try {
      const { data } = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/reference-assessment/generate-test`, payload);
      commit('SET_QUESTIONNAIRE', data);
    } catch (error) {
      console.error('Error Fetching  Suggestions', error);
    }
  },

  // add/remove vector Store from brain
  // toggleAddOrRemoveVectorFromBrain(_, {
  //   // payload
  //   id, action
  // }) {

  //   // body for axios call
  //   const requestBody = {
  //     id,
  //     action
  //   }
  // },
};
