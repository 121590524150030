/* eslint-disable import/no-cycle */
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true, // works for actions, mutations and getters... because for state its namespaced by default
  state: {
    mainAssistantId: 'asst_nTRgke9YRNi8kwzv2JNwdeU6',
    brains: [],
  },
  actions,
  mutations,
  getters,
  questionnaire: [],
};
