import axios from 'axios';
// eslint-disable-next-line import/no-cycle
// import router from '../../routecr';

export default {
  namespaced: true,

  state: {
    connected: false,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async sendGameFeedback(_, {
      gameSessionId, userFeedback,
    }) {
      const token = localStorage.getItem('userToken');
      const response = await axios
        .post(`${process.env.VUE_APP_AXIOS_URI_BASE}/api/v1/game-story/feedback`,
          {
            gameSessionId,
            userFeedback,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

      return response;
    },
    toggleConnected({ commit }, payload) {
      commit('TOGGLE_CONNECTED', payload.value);
    },
  },
  mutations: {
    TOGGLE_CONNECTED(state, payload) {
      state.connected = payload;
    },
  },
};
