<template>
   <div class="notification-container" v-if="feedbackNotification">
    <!-- SweetAlert will handle the notification display -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import notificationMessages from '@/locales/notificationTexts/notificationTexts.json';
import Swal from 'sweetalert2';

export default {
  computed: {
    ...mapState(['feedbackNotification']),
    notificationMessage() {
      return notificationMessages[this.feedbackNotification] || this.feedbackNotification;
    },
  },
  watch: {
    feedbackNotification(newVal) {
      if (newVal) {
        Swal.fire({
          position: 'bottom-end',
          icon: 'info',
          title: this.notificationMessage,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          toast: true,
          showCloseButton: true,
          margin: '50px',
          width: '300px',
        });
        setTimeout(() => {
          this.$store.commit('clearFeedbackNotification');
        }, 5000);
      }
    },
  },
};
</script>

<style scoped>
</style>
