// myActions.js

import axios from 'axios';

// const baseURL = `${process.env.VUE_APP_AXIOS_URI_BASE}/api/v1`; // Replace '/api/v1' with your actual base URL for the API

// const api = axios.create({
//   baseURL,
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem('userToken')}`,
//   },
// });

export default {
  async addGoal({ dispatch, commit }, payload) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/goals`, {
        goal: payload.goal,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      }); // Replace '/goals' with your actual API endpoint for creating a goal
      const { goal } = response.data;
      // console.log('xzy-f', response.data);
      commit('ADD_GOAL', goal);
      const eventPayload = {
        verb: 'CREATED_GOAL',
        value: {
          title: payload.goal,
        },
      };
      dispatch('eventToEventStore', eventPayload, { root: true });
      dispatch('actionFeedback', eventPayload, { root: true });
      return goal;
    } catch (error) {
      // Handle error
      throw new Error(error.message);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async fetchGoal({ }, payload) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/goals/${payload.goalId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      });
      const { goal } = response.data;
      return goal;
    } catch (error) {
      return {
        goal: 'goal Does not exists',
        status: false,
      };
    }
  },

  async updateGoal({ commit }, goal) {
    try {
      await axios.put(`${process.env.VUE_APP_AXIOS_URI}/goals/${goal.id}`, goal, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      }); // Replace '/goals/:id' with your actual API endpoint for updating a goal
      commit('UPDATE_GOAL', goal);
    } catch (error) {
      // Handle error
    }
  },

  async markAsComplete({ dispatch, commit }, payload) {
    try {
      const response = await axios.put(`${process.env.VUE_APP_AXIOS_URI}/goals/${payload.goal.id}`, {
        payload: {
          status: payload.goal.status,
        },
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      });
      const { goal } = response.data;
      commit('UPDATE_GOAL', goal);
      // trigger node closed event
      const eventPayload = {
        verb: 'CLOSED_GOAL',
        value: {
          title: goal.goal,
        },
      };
      dispatch('eventToEventStore', eventPayload, { root: true });
      dispatch('actionFeedback', eventPayload, { root: true });
      if (goal.status) {
        commit('CREATE_ATTACHMENT_REQUEST', {});
      }
    } catch (error) {
      // Handle error
    }
  },

  async updateAttachment({
    state, commit, dispatch, rootGetters,
  }, payload) {
    console.log('xvf', payload);
    try {
      const response = await axios.put(`${process.env.VUE_APP_AXIOS_URI}/goals/${payload.goal.id || state.selectedGoal.id}`, { payload: payload.query }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      });
      const { goal } = response.data;
      let eventPayload;
      eventPayload = {
        verb: 'EDITED_GOAL',
        value: {
          title: goal.goal,
        },
      };
      dispatch('eventToEventStore', eventPayload, { root: true });
      dispatch('actionFeedback', eventPayload, { root: true });
      commit('UPDATE_GOAL', goal);
      // trigger goal updated Event

      if (payload.query.attachment.push) {
        eventPayload = {
          verb: 'ATTACHED_TO_GOAL',
          value: {
            title: goal.goal,
            note: payload.additionals.note,
          },
        };
      } else {
        eventPayload = {
          verb: 'DETACHED_FROM_GOAL',
          value: {
            title: goal.goal,
            note: payload.additionals.note,
          },
        };
      }
      dispatch('eventToEventStore', eventPayload, { root: true });
      dispatch('actionFeedback', eventPayload, { root: true });
      if (payload.query.attachment) {
        let note; let
          updatedNote;
        if (payload.query.attachment.push) {
          /**
           * if attachment is pushed on goal
           * on status 200
           *  - fetch note with NoteId [X]
           *  - attach goal with Note [X]
           *  - update note in notes Library [X]
           *  - update goal by adding 1 to attachment count [ ]
          */
          note = rootGetters.fetchNoteById(payload.query.attachment.push);
          updatedNote = { ...note, goal: state.selectedGoal.id };
        } else if (payload.query.attachment.delete) {
          note = rootGetters.fetchNoteById(payload.query.attachment.delete);
          updatedNote = { ...note, goal: undefined };
        }
        dispatch('updateNoteInLibrary', updatedNote, { root: true });
        commit('CREATE_ATTACHMENT_REQUEST', {});
      } else if (payload.query.goal) {
        console.log('fetchGoalWithId goal');
      } else {
        console.log('fetchGoalWithId any');
      }
    } catch (error) {
      // Handle error
    }
  },

  async deleteAttachment({ commit }, attachmentId) {
    try {
      await axios.delete(`${process.env.VUE_APP_AXIOS_URI}/attachments/${attachmentId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      }); // Replace '/attachments/:id' with your actual API endpoint for deleting an attachment
      commit('DELETE_ATTACHMENT', attachmentId);
    } catch (error) {
      // Handle error
    }
  },

  async deleteGoal({ dispatch, commit }, payload) {
    try {
      await axios.delete(`${process.env.VUE_APP_AXIOS_URI}/goals/${payload.goal.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      }); // Replace '/goals' with your actual API endpoint for deleting multiple goals
      commit('DELETE_GOAL', payload.goal.id);
      const eventPayload = {
        verb: 'DISCARDED_GOAL',
        value: {
          title: payload.goal.goal,
        },
      };
      dispatch('eventToEventStore', eventPayload, { root: true });
      dispatch('actionFeedback', eventPayload, { root: true });
    } catch (error) {
      // Handle error
    }
  },

  async fetchGoals({ commit }) {
    try {
      // const response = await api.get(''); // Replace '/goals' with your actual API endpoint for fetching goals
      setTimeout(async () => {
        const response = await axios.get(`${process.env.VUE_APP_AXIOS_URI}/goals`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        const { goals } = response.data;
        commit('SET_GOALS', goals);
      }, 3000);
    } catch (error) {
      // Handle error
    }
  },

  async createAttachmentRequest({ state, commit }, payload) {
    try {
      if (state.selectedGoal && state.selectedGoal.id === payload.goal.id) {
        commit('CREATE_ATTACHMENT_REQUEST', {});
      } else {
        commit('CREATE_ATTACHMENT_REQUEST', payload.goal);
      }
    } catch (error) {
      // handle error
    }
  },

  // eslint-disable-next-line no-empty-pattern, consistent-return
  async fetchGoalsWithIds({}, goalIds) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_AXIOS_URI}/goals/many`, {
        goal_ids: goalIds,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      }); // Replace '/goals' with your actual API endpoint for fetching goals
      const { goals } = response.data;
      return goals;
    } catch (error) {
      // Handle error
    }
  },

  toggleAddModal({ commit }) {
    commit('TOGGLE_ADD_MODAL');
  },
};
