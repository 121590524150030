<template>
  <div class="horizontal-layout">
    <Sidebar />
    <Navbar />
    <div
      :class="sideContainerClass"
      class="main__container"
    >
      <slot />
    </div>
    <BottomDock />
    <Camera />
  </div>
</template>

<script>
import Navbar from '@/common/components/Navbar.vue';
import Sidebar from '@/common/components/Sidebar.vue';
import BottomDock from '@/common/components/BottomDock.vue';
import Camera from '@/common/components/Camera.vue';

export default {
  name: 'HorizontalLayout',
  components: {
    Navbar,
    Sidebar,
    BottomDock,
    Camera,
  },
  data() {
    return {
      sideContainerClass: '',
    };
  },
  watch: {
    sidebarToggleStatus(val) {
      if (val) {
        this.sideContainerClass = 'squeeze-for-sidebar';
      } else {
        this.sideContainerClass = 'expand-for-sidebar';
      }
    },
  },
  computed: {
    sidebarToggleStatus() {
      return this.$store.state.uiStates.sidebarToggleStatus;
    },
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/navbar.scss";
</style>
