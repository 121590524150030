function registerLayouts(Vue) {
  // Use require.context to dynamically import all .vue files in the current directory
  const context = require.context('./', true, /\.vue$/);

  // Iterate over the keys (file paths) in the context
  context.keys().forEach((fileName) => {
    // Get the component config
    const componentConfig = context(fileName);

    // Get the component name, assuming the component's default export has a 'name' property
    const componentName = componentConfig.default.name;

    // Register the component globally
    if (componentName) {
      Vue.component(componentName, componentConfig.default);
    }
  });
}

export default registerLayouts;
