import axios from 'axios';

export default {
  namespaced: true,

  state: {
    assessmentId: null,
  },
  mutations: {
    setAssessmentId(state, id) {
      state.assessmentId = id;
    },
  },
  actions: {
    async createAssessment({ commit }, payload) {
      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_AXIOS_URI}/assessments`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        const assessment = response.data;
        console.log('Response:', assessment);
        if (assessment) {
          commit('setAssessmentId', assessment.id);
        }
        return assessment;
      } catch (error) {
        console.error('Error:', error.response.data);
        throw error;
      }
    },

    async updateAssessment({ state }, { status, result }) {
      const payload = {
        result,
        status,
      };

      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_AXIOS_URI}/assessments/${state.assessmentId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        const { updatedAssessment } = response.data;
        console.log('Response:', updatedAssessment);
        return response.data;
      } catch (error) {
        console.error('Error:', error.response.data);
        throw error;
      }
    },

    async askEddieForResponse(_, { prompt, intentId, callback }) {
      const payload = {
        prompt,
        context: '',
        node_info_section: {
          video: false,
          questions: false,
          article: false,
        },
        prompt_id: intentId,
        stream: true,
      };

      const token = localStorage.getItem('userToken');
      const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/eddie/prompt/info/stream`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let chunk;
      // eslint-disable-next-line no-cond-assign, no-await-in-loop
      while (!(chunk = await reader.read()).done) {
        const decodedChunk = decoder.decode(chunk.value);
        callback(decodedChunk);
      }
    },
  },
  getters: {
    getAssessmentId: (state) => state.assessmentId,
  },
};
