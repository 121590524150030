import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/PageHome.vue';
import Auth from '@/views/AuthPage.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import RequestForgotPassword from '@/views/RequestForgotPassword.vue';
import oAuthRequest from '@/views/oAuthPage.vue';
import PageEmbed from '@/views/PageEmbed.vue';
// eslint-disable-next-line import/no-cycle
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      layout: 'HorizontalLayout',
    },
  },
  {
    path: '/embed',
    name: 'Home',
    component: PageEmbed,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'Forgot-Password',
    component: ForgotPassword,
    props: true,
    meta: {
      requiresGuest: true,
      layout: 'BlankLayout',
    },
  },
  {
    path: '/auth/reset-password-redirect/:passwordRequestKey',
    name: 'Forgot-Password-Request',
    component: RequestForgotPassword,
    props: true,
    meta: {
      requiresGuest: true,
      layout: 'BlankLayout',
    },
  },
  {
    path: '/auth/reset-password/:passwordRequestKey',
    name: 'Reset-Password',
    component: ResetPassword,
    props: true,
    meta: {
      requiresGuest: true,
      layout: 'BlankLayout',
    },
  },
  {
    path: '/auth/oauth-request/:oauthRequestKey',
    name: 'OAuth-Request',
    component: oAuthRequest,
    props: true,
  },
  {
    path: '/auth/:action',
    name: 'Auth',
    component: Auth,
    props: true,
    meta: {
      requiresGuest: true,
      layout: 'BlankLayout',
    },
  },
  {
    path: '/thread/:roomId',
    name: 'ThreadRoom',
    component: () => import('@/common/components/ThreadRoom.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/profile/edit',
    name: 'edit-profile',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/common/components/EditProfile.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/file/:fileId',
    name: 'fetch-file',
    component: () => import('@/common/components/FetchFile.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/thought/:id',
    name: 'Thought',
    component: Home,
    props: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/PageDashboard.vue'),
    meta: {
      requiresAuth: true,
      layout: 'HorizontalLayout',
    },
  },
  {
    path: '/embed/:id/:search',
    name: 'embed',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/embed/FrameBed.vue'),
  },
  {
    path: '/game/intent/:intentId/:searchTerm',
    name: 'IntentLoader',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/common/components/game/IntentLoader.vue'),
  },
  {
    path: '/game/feedback',
    name: 'GameFeedback',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/common/components/game/GameFeedback.vue'),
  },
  {
    path: '/experiment',
    name: 'experiment',
    component: () => import(/* webpackChunkName: "experiment" */ '@/common/components/Experiment.vue'),
    meta: {
      requiresAuth: true,
      layout: 'HorizontalLayout',
    },
  },
  {
    path: '/canvas',
    name: 'canvas',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/PageCanvas.vue'),
    meta: {
      requiresAuth: true,
      layout: 'HorizontalLayout',
    },
  },
  {
    path: '/Simplified-view',
    name: 'SimplifiedView',
    component: () => import(/* webpackChunkName: "firstView" */ '@/views/PageSimplifiedView.vue'),
    meta: {
      requiresAuth: true,
      layout: 'HorizontalLayout',
    },
  },
  {
    path: '/Author-view',
    name: 'AuthorView',
    component: () => import(/* webpackChunkName: "firstView" */ '@/views/PageAuthorView.vue'),
    meta: {
      requiresAuth: true,
      layout: 'HorizontalLayout',
    },
  },
  {
    path: '/Reading-view',
    name: 'ReadingView',
    component: () => import(/* webpackChunkName: "firstView" */ '@/views/PageReadingView.vue'),
    meta: {
      requiresAuth: true,
      layout: 'HorizontalLayout',
    },
  },
  {
    path: '/widget/:name',
    name: 'WidgetView',
    component: () => import(/* webpackChunkName: "firstView" */ '@/views/WidgetView.vue'),
    props: (route) => ({
      name: route.params.name,
      ...route.query,
    }),
    meta: {
      requiresAuth: true,
      layout: 'BlankLayout',
    },
  },
  {
    path: '/mobile-view',
    name: 'MobileView',
    component: () => import(/* webpackChunkName: "firstView" */ '@/views/PageMobileView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/graph/:id',
    name: 'GraphPage',
    component: () => import(/* webpackChunkName: "graph" */ '@/views/PageGraph.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/VR',
    name: 'VR Dashboard',
    component: () => import(/* webpackChunkName: "vr" */ '@/views/PageVR.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/PageSettings.vue'),
    meta: {
      requiresAuth: true,
      layout: 'HorizontalLayout',
    },
  },
  {
    path: '/accessibility',
    name: 'Accessibility',
    component: () => import(/* webpackChunkName: "Accesibility" */ '@/views/PageAccesibility.vue'),
    meta: {
      requiresAuth: true,
      layout: 'HorizontalLayout',
    },
  },
  {
    path: '/privacyPolicy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '@/views/PrivacyPolicy.vue'),
  },
  {
    path: '/termsOfUse',
    name: 'Terms Of Use',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '@/views/TermsOfUse.vue'),
  },
  {
    path: '/logout',
    name: 'SignOut',
    meta: {
      requiresAuth: true,
    },
    beforeEnter(to, from, next) {
      store.dispatch('auth/logout').then(() => next({
        name: 'Home',
      }));
    },
  },
  {
    path: '/note/:id',
    name: 'note_page',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '@/views/NotePage.vue'),
    meta: {
      requiresAuth: true,
    },
    async beforeEnter(to, from, next) {
      // params id

      await store.dispatch('getNote', to.params.id);
      next();
    },
  },
  {
    path: '/tag/:name',
    name: 'tag_page',
    meta: {
      requiresAuth: true,
    },
    async beforeEnter(to, from, next) {
      // params id

      await store.dispatch('fetchTagContent', to.params.name);
      next();
    },
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '@/views/TagPage.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@/views/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   // protected route
//   store.dispatch('setRouteDetail', to.name);
//   if (to.name === 'embed') next();
// })

router.beforeEach((to, from, next) => {
  // protected route
  store.dispatch('setRouteDetail', to.name);
  if (to.name === 'embed') next();
  if (to.name === 'NotFound') next();

  store.dispatch('auth/initAuthentication').then((user) => {
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      if (user) {
        /**
           * WARNING: This is only implemented for
           * demostration purpose may not practised
           * further without permissions.
           * */

        next();
      } else {
        next({
          name: 'Auth',
          params: 'login',
        });
      }
    } else if (to.matched.some((route) => route.meta.requiresGuest)) {
      if (!user) {
        next();
      } else {
        next({
          name: 'Home',
        });
      }
    } else {
      next();
    }
  }).catch((err) => {
    next({
      name: 'NotFound',
    });
    throw err;
  });
});

export default router;
