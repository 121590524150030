<template>
  <component :is="getLayoutFromMeta">
    <keep-alive>
        <router-view :key="$route.fullPath" />
    </keep-alive>
  </component>
</template>

<script>
export default {
  name: 'LayoutComponent',
  computed: {
    getLayoutFromMeta() {
      // Check if layout is provided in the query parameters
      if (this.$route.query.layout) {
        return this.$route.query.layout;
      }

      // Fallback to the layout in meta property
      if (this.$route.meta.layout) {
        return this.$route.meta.layout;
      }

      // Default to 'div' if no layout is found
      return 'div';
    },

  },
};
</script>
