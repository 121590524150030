// eslint-disable-next-line import/no-cycle
import store from '@/store';
import graphReactor from '@/common/core/Graph/graphReactor';
import edgesMap from '@/common/core/Graph/edgesMap';
import chroma from 'chroma-js';
// const chroma = require('chroma-js');

const exploreMap = async (exploreData) => {
  const nodes = [];
  const edges = [];
  const currentColor = chroma.random().darken().hex();
  let findNode;
  if (graphReactor.exploreInstance) {
    findNode = graphReactor.exploreInstance.find('node', (node) => node.get('model').id === exploreData[0].title);
  }

  const mainNode = {
    id: exploreData[0].title,
    label: exploreData[0].title,
    type: 'circle',
    size: 40,
    style: {
      fill: '#fff',
      stroke: '#333',
    },
    tjType: 'wikiLinks',
    cluster: exploreData[0].title,
    // icon: {
    //   show: true,
    //   img: 'https://edverve.netlify.app/img/logo.39aa2eba.svg',
    //   width: 20,
    //   height: 20,
    // },
  };

  if (!findNode) {
    nodes.push(mainNode);
  }

  const epxloreSlicedData = exploreData.slice(1);
  let edgeCheck = null;
  let edgesDetail = null;

  if (store.state.isEdgeMap) {
    // ? wait for your return
    const exploreEdgeMap = await edgesMap(epxloreSlicedData, mainNode.label);
    // ? extract values
    edgesDetail = Object.values(exploreEdgeMap);
    // ? all label to check
    edgeCheck = edgesDetail.map((item) => item.label);
  }
  epxloreSlicedData.forEach((item) => {
    const data = {
      id: item.title,
      label: item.title,
      type: 'circle',
      tjType: 'wikiLinks',
      style: {
        fill: currentColor,
        stroke: '#fff',
      },
      cluster: exploreData[0].title,
    };
    nodes.push(data);
    if (store.state.isEdgeMap) {
      if (edgeCheck.includes(item.title)) {
        // ? if it exists
        edges.push({
          source: exploreData[0].title,
          target: item.title,
          label: edgesDetail.find((itemEdge) => itemEdge.label === item.title).edgeLabel, // ? find and insert
        });
      } else {
        edges.push({
          source: exploreData[0].title,
          target: item.title,
        });
      }
    } else {
      edges.push({
        source: exploreData[0].title,
        target: item.title,
      });
    }
  });
  // Filter nodes if you find the node in the graph.
  if (findNode) {
    // eslint-disable-next-line
    const finalNodes = nodes.filter((item) => item.id !== findNode._cfg.id);
    const graphExploreData = {
      nodes: finalNodes,
      edges,
    };

    return graphExploreData;
  }
  const graphExploreData = {
    nodes,
    edges,
  };

  return graphExploreData;
};

export default exploreMap;
