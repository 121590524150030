import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import mutations from './mutations';
// eslint-disable-next-line
import actions from './actions';
import getters from './getters';
// eslint-disable-next-line import/no-cycle
import auth from './modules/auth';
// eslint-disable-next-line
import gmodule from './modules/graph';
import searchEngine from './modules/searchEngine';
import points from './modules/points';
import layouts from './modules/layout';
import goals from './modules/goals';
import quests from './modules/quests';
import game from './modules/game';
import codeCompiler from './modules/codeCompiler';
import thirdPartyIntegrations from './modules/thirdPartyIntegrations';
import eddie from './modules/eddie';
import assessments from './modules/assessments';

const dataState = createPersistedState({
  paths: ['tree', 'notificationCount'],
});

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    eventsData: [],
    archivedData: [],
    transcription: [],
    audio: [],
    isLoading: false,
    youtube_search_result: null,
    youtube_search_next_token: null,
    pageTokens: {},
    all_quests: [],
    current_quest_id: '',
    eric_records: [],
    nav_data: {
      nodes: [],
      edges: [],
    },
    openai_meta: {
      is_searching: false,
      showHistory: false,
    },
    detail_description: '',
    //  Experimental
    current_thought: {},
    last_thought: {},
    thought_stack: [],
    excludeCallList: [],
    // Very experimental
    // Quest Controllers
    questMode: false,
    wolframInformation: {
      question: '',
      answer: '',
    },
    groupMode: false,
    saved_graph_list: [],
    graph_library_view: 'list',
    isThoughtMapRecording: true,
    screenRecorder: {
      url: '',
      // captionUrl: '',
    },
    screenRecordCaption: [],
    edit_mode: 'looked_at',
    node_info_section: {
      video: true,
      questions: true,
      article: true,
    },
    allComponentList: [
      'Home',
      'SecondView',
      'ThirdView',
      'ThreeDmodelWidget',
      'WolFramWidget',
      'BookLibraryWidget',
      'CompareWidget',
      'Wikidatacardwidget',
      'DendographWidget',
      'DetailWidget',
      'DimensionWidget',
      'EddieWidget',
      'EditorWidget',
      'GlossaryWidget',
      'GraphControlsWidget',
      'GraphExploreWidget',
      'GraphExploreVrwidget',
      'GraphIndexWidget',
      'GraphLibraryWidget',
      'GutenbergLibrary',
      'HelpWidget',
      'iframewidget',
      'InformationWidget',
      'LinkedThoughtsWidget',
      'NotesLibraryWidget',
      'QuestLibraryWidget',
      'RecentActivityWidget',
      'Recentevents',
      'SearchEngineWidget',
      'SnippetWidget',
      'ThoughtMapWidget',
      'G6nodeFilterWidget',
      'ThoughtVerseWidget',
      'ThoughtversecontrolsWidget',
      'ThoughtversealtWidget',
      'TreasureMaker',
      'Twitterfeed',
      'VideosWidget',
      'UserEventsGraphWidget',
    ],
    widgetName: '',
    edge_strength: 300,
    newNodeLabel: '',
    newNodeType: 'custom',
    newEdgeLabel: '',
    active_graph_id: {
      explore_library: '',
      quest_library: '',
    },
    g6_graph_data: {
      nodes: [],
      edges: [],
    },
    cameraStatus: false,
    notificationCount: 0,
    verb: null,
    books: [],
    currentBook: null,
    wikiWord: null,
    user: {},
    explore: {
      entity: '',
      extract: '',
      links: [],
    },
    tree: {
      dir: 'horizontal',
      first: {
        dir: 'horizontal', // Left | Right
        // Other split
        first: {
          dir: 'vertical',
          first: 0, // these numbers represents the views slot ID
          second: 2,
        },
        second: {
          dir: 'horizontal',
          first: {
            dir: 'vertical',
            first: 1,
            second: 5,
            split: '70%',
          },
          second: {
            dir: 'vertical',
            first: 3,
            second: 4,
            split: '50%',
          },
          split: '65%',
        },
        split: '25%',
      },
      second: 6,
      split: '75%',
    },
    wikiInformation: [],
    selectedNode: null,
    loginEntities: {
      accessToken: null,
      tokenType: null,
    },
    graph: {
      nodes: [
        { id: 1, label: 'first' },
        { id: 2, label: 'second' },
      ],
      links: [{ source: 1, target: 2 }],
      setting: {
        showSettings: true,
        nodeFilter: {},
        edgeFilter: {},
      },
    },
    g6_graph: {},
    visited_graph: {
      nodes: [
        { id: 1, label: 'first' },
        { id: 2, label: 'second' },
      ],
      links: [{ source: 1, target: 2 }],
    },
    userInfo: {
      // Added a default user which will act as Anonymous user
      id: '-1',
      username: 'anonymous',
      avatar: null,
      discriminator: 'EDDY',
      public_flags: 0,
      flags: 0,
      locale: 'en-US',
      mfa_enabled: false,
      email: 'anonymous-user@anonymous.com',
      verified: false,
    },
    isLoggedIn: false,
    wikiSnippet: null,
    uiStates: {
      sidebarToggleStatus: false,
      notificationToggleStatus: false,
    },
    eventsRaw: [],
    eventsVerb: [],
    notes: {
      data: [],
    },
    mutationSeries: [],
    autocompleteSuggestionData: [],
    loaderFeed: {
      // 1: {
      //   start: 123123,
      //   event: 'Mutation',
      //   message: 'Custom message',
      // },
    },
    eddie_result: {
      response: '',
    },
    routeInfo: '',
    isEdgeMap: false,
    // g6_graph_data: [],
    componentKey: 0,
    twitter_data: [],
    settings: {
      social: null,
      widget: {
        showExplore: true,
        showWolfram: true,
        showWikidata: true,
        show3DViewer: true,
      },
    },
    current_selected_entity: {},
    last_selected_entity: {},
    mostSearchedWords: [],
    compareList: [],
    explore_description: '',
    is_uploading_data: false,
    isQuestUploading: false,
    explore_description_settings: {
      selectedMode: 'Default',
      modes: [
        {
          mode: 'Default',
          description: 'shows data in brief',
        },
        {
          mode: 'Detailed',
          description: 'shows data in detailed modes',
        },
        {
          mode: 'Marked Up',
          description: 'shows all Marked Up data in Marked Up Mode',
        },
        {
          mode: 'Entities',
          description: 'shows all the entities in this mode',
        },
        {
          mode: 'Sentences',
          description: 'shows the sentences modes',
        },
      ],
    },
    singleNote: null,
    tagContent: null,
    explore_data_range: 8,
    pie_data: {},
    payload_detail: {
      tjType: 'note-text',
      description: {
        content: 'You haven\'t selected a node yet',
        marked: '',
        detailed: '',
        sentences: '',
        entities: '',
      },
      tjData: null,
    },
    eddie_history: [],
    activeLayoutKey: '_b',
    admin: ['flawsophies', 'pkkarn', 'kaaro', 'sourabh.k.ed', 'nischay.s.ed'],
    compareDetails: null,
    threeDModelData: null,
    suggestionData: [],
    gutenbergBooks: null,
    gutenbergBook: null,
    locale: {
      currentLocale: 'en',
      availableLocales: [
        { flag: 'us', language: 'en', title: 'English' },
        { flag: 'in', language: 'hi', title: 'Hindi' },
        { flag: 'es', language: 'es', title: 'Spanish' },
        { flag: 'de', language: 'de', title: 'German' },
      ],
      fallbackLocale: 'en',
    },
    currentMarkdown: {
      frontmatter: {},
      markdown: '',
    },
    text_to_speech: '',
    test: 'test_root',
    library_mode_quest: false,
    graphConfigs: {
      gforce: {
        edgeStrength: 300,
        nodeStrength: 1000,
        linkDistance: 100,
        preventOverlap: true,
      },
      force: {
        linkDistance: 100,
        nodeStrength: 100,
        edgeStrength: 100,
        nodeSize: 20,
        preventOverlap: false,
      },
      dagre: {
        nodesep: 10,
        ranksep: 10,
        rankdir: 'LR',
        align: 'DL',
      },
      concentric: {
        linkDistance: 100,
        equidistance: false,
        nodeSize: 30,
        sweep: 10,
      },
      radial: {
        linkDistance: 100,
        maxIteration: 1000,
        unitRadius: 100,
        focusNode: '1',
        preventOverlap: true,
        nodeSize: 30,
      },
      circular: {
        startRadius: 50,
        endRadius: 100,
        clockwise: false,
        preventOverlap: true,
      },
      fruchterman: {
        gravity: 10,
        speed: 10,
        clusterGravity: 10,
        maxIteration: 100,
        preventOverlap: true,
      },
      mds: {
        linkDistance: 10,
        preventOverlap: true,
      },
      grid: {
        rows: 3, // rows
        cols: 3, // columns
        nodeSize: 30,
        preventOverlap: true,
      },
      layoutType: 'GForce',
      focusNodeList: [],
      edgeStrength: 300,
      nodeStrength: 1000,
      linkDistance: 100,
    },
    // newAnaltics
    userAnalytics: '',
    feedbackNotification: '',
    editorAIFetch: '',
    hslState: true,
    colors: [
      '#8FE9FF',
      '#87EAEF',
      '#FFC9E3',
      '#A7C2FF',
      '#FFA1E3',
      '#FFE269',
      '#BFCFEE',
      '#FFA0C5',
      '#D5FF86',
    ],

  },
  getters,
  actions,
  mutations,
  modules: {
    auth,
    gmodule,
    searchEngine,
    points,
    layouts,
    goals,
    quests,
    codeCompiler,
    game,
    eddie,
    thirdPartyIntegrations,
    assessments,
  },
  plugins: [dataState],
});
