function toggleJsonContent(event, id) {
  const halfContent = document.querySelector(`[data-content='${id}--half']`);
  const fullContent = document.querySelector(`[data-content='${id}--full']`);
  const button = event.target;

  if (button.innerText === 'Show more') {
    if (halfContent && fullContent) {
      halfContent.classList.add('hidden');
      fullContent.classList.remove('hidden');
      button.innerText = 'Show less';
    }
  } else if (halfContent && fullContent) {
    halfContent.classList.remove('hidden');
    fullContent.classList.add('hidden');
    button.innerText = 'Show more';
  }
}

// eslint-disable-next-line import/prefer-default-export
export { toggleJsonContent };
