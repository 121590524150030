<template>
    <div class="app">
        <slot/>
    </div>
</template>

<script>
export default {
  name: 'BlankLayout',
};
</script>

<style>

</style>
