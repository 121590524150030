<template>
  <div class="home-container">
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Layout Editor</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="split-layout-comp">
          <FeedbackNotification/>
      <Layout :key="componentKey" :resize="resize" :edit="edit" :splits="tree">
        <Pane
          v-for="(layoutKey, index) in layoutKeys"
          :key="layoutKey"
          class="split-layout-custom-style"
        >
          <div class="tab-top nav-box nav-box--pd-52">
            <ul class="nav nav-tabs nav-overflow">
              <li
                v-for="(value, key) in layout[layoutKey]"
                :key="key"
                class="nav-item"
              >
                <div
                  class="nav-link d-flex align-items-center widget-tab"
                  :class="
                    layout_selection[layoutKey] === key
                      ? 'widget-tab__active'
                      : ''
                  "
                  @click="updateLayout(layoutKey, key)"
                  @click.middle="removeTabFromLayout(layoutKey, key)"
                  :title="getShortcut(findTabTitle(value), findTabTitle(value))"
                >
                  <i
                    class="
                      custom-help-css
                      box-icon
                      widget-tab__button widget-tab__button--front
                      bx bx-help-circle
                    "
                    :value="value"
                    :title="getShortcut('Help', 'Help')"
                    @click="help(value)"
                  ></i>
                  <div class="tab-title">
                    {{ findTabName(value) }}
                  </div>
                  <i
                    class="
                      custom-box-css
                      box-x box-icon
                      widget-tab__button widget-tab__button--back
                      bx bx-x
                    "
                    @click.stop="removeTabFromLayout(layoutKey, key)"
                    title="Close"
                  ></i>
                </div>
              </li>
              <li class="nav-item">
                <div class="dropdown nav-icon">
                  <component-dropdown-vue :layoutKey="layoutKey" :addToWidget="addTabToLayout" v-if="abilities.can('component_dropdown', 'service')"/>
                  <label class="radio_for_active" v-if="abilities.can('focus_panel', 'service')">
                    <input
                      type="radio"
                      v-model="active_key"
                      name="activeComponent"
                      :value="layoutKey"
                    />
                    <i
                      v-if="abilities.can('focus_panel', 'service')"
                      class="
                        active_comp_class
                        box-icon
                        bx bx-radio-circle-marked
                      "
                      :class="`
                        ${
                          layoutKey === active_key
                            ? 'icon-active icon-active--highlight'
                            : 'icon-inactive'
                        }
                      `"
                    ></i>
                    <i class="box-icon bx bx-window-close" @click="removed(index)" title="Close Panel" v-if="abilities.can('close_panel', 'service')"></i>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div class="tab-full">
            <keep-alive>
              <component
                :is="layout[layoutKey][layout_selection[layoutKey]]"
              ></component>
            </keep-alive>
          </div>
        </Pane>
      </Layout>
    </div>
  </div>
</template>

<script>
import { Layout, Pane } from 'vue-split-layout';
import Branch from '@/tree/branch';
import Tree from '@/tree';
// eslint-disable-next-line import/no-cycle
import AbilityMixin from '@/mixins/AbilityMixin';
import ComponentDropdownVue from '@/common/components/ComponentDropdown.vue';
import FeedbackNotification from '@/common/components/FeedbackNotification.vue';
import { mapGetters } from 'vuex';
import {
  coreComponents,
  coreComponentOptions,
} from '../config/componentLoader';

export default {
  name: 'Home',

  metaInfo: {
    title: 'Home',
  },

  activated() {
    // This hook is called when the component is reused (activated)
    this.roles = this.$store.getters['auth/authUser'].roles;
    this.updateLayoutBasedOnState();
  },

  components: {
    Layout,
    Pane,
    ComponentDropdownVue,
    ...coreComponents,
    FeedbackNotification,
  },
  mixins: [AbilityMixin],
  data() {
    return {
      compfilter: '',
      active_key: '_b',
      componentKey: 0,
      tree: {
        dir: 'horizontal', // Left | Right
        // Other split
        first: {
          dir: 'vertical',
          first: 0, // these numbers represents the views slot ID
          second: 2,
          split: '100%',
        },
        second: {
          dir: 'horizontal',
          first: 1,
          second: 5,
          split: '70%',
        },
        split: '20%',
      },
      layoutKeys: ['_a', '_b', '_c', '_d', '_e', '_f'],
      layout: {
        _a: ['QuestLibraryWidget'],
        _b: ['SearchEngineWidget'],
        _c: ['SnippetWidget'],
        _d: ['WikiDataCardWidget'],
        _e: ['DetailWidget'],
        _f: ['GraphIndexWidget'],
      },
      layout_selection: {
        _a: 0,
        _b: 0,
        _c: 0,
        _d: 0,
        _e: 0,
        _f: 0,
      },
    };
  },

  watch: {
    active_key(val) {
      this.$store.dispatch('setActiveLayoutKey', val);
    },
  },
  methods: {
    removed(index) {
      this.treeHook.remove(index);
      this.tree = this.treeHook.tree;
      this.componentKey += 1;
    },
    findTabName(value) {
      const tab = this.componentsOptions.find((item) => item.name === value);
      return tab?.options?.title;
    },
    findTabTitle(value) {
      const tab = this.componentsOptions.find((item) => item.name === value);
      return tab?.name;
    },
    help(value) {
      this.$store
        .dispatch('fetchWidgetName', this.convertToKebabCase(value))
        .then(() => {
          this.$store.dispatch('activateLayout', {
            layoutKey: '_f',
            componentName: 'HelpWidget',
          });
        });
    },
    addPanel() {
      const panelId = `_${String.fromCharCode(
        this.layoutKeys[this.layoutKeys.length - 1].charCodeAt(1) + 1,
      )}`;
      const newPanelId = String(panelId);

      this.layoutKeys = [...this.layoutKeys, newPanelId];

      const newLayout = {};
      const newLayoutSelection = {};
      Object.keys(this.layout).forEach((key) => {
        newLayout[key] = this.layout[key];
      });
      newLayout[newPanelId] = [];

      this.layout = newLayout;
      Object.keys(this.layout_selection).forEach((key) => {
        newLayoutSelection[key] = this.layout_selection[key];
      });
      newLayoutSelection[newPanelId] = 0;
      this.layout_selection = newLayoutSelection;
      const customTree = new Branch({
        dir: 'horizontal',
        first: this.tree,
        second: this.layoutKeys.length - 1,
        split: '94%',
      });

      this.tree = customTree;
      this.treeHook.tree = this.tree;
      this.treeHook.init();

      this.componentKey += 1;
      // this.customTree = {
      //   dir: horizontal,

      // };
    },
    updateLayout(key, index) {
      if (this.roles[0] === 'BootcampFall23') {
        this.layout_selection[key] = index;
        const mountedWidgetName = this.$store.state.layouts.bootcampView.layout_tabs[key][this.layout_selection[key]];
        if (mountedWidgetName === 'DendroGraphWidget') {
          this.$store.dispatch('quests/setQuestMode', true);
        } else if (mountedWidgetName === 'GraphExploreWidget') {
          this.$store.dispatch('quests/setQuestMode', false);
        }
        this.updateLayoutToState();
      } else {
        this.layout_selection[key] = index;
        const mountedWidgetName = this.$store.state.layouts.exploreView.layout_tabs[key][this.layout_selection[key]];
        if (mountedWidgetName === 'DendroGraphWidget') {
          this.$store.dispatch('quests/setQuestMode', true);
        } else if (mountedWidgetName === 'GraphExploreWidget') {
          this.$store.dispatch('quests/setQuestMode', false);
        }
        this.updateLayoutToState();
      }
    },
    removeTabFromLayout(key, index) {
      this.layout[key].splice(index, 1);
      this.updateLayoutToState();
    },
    addTabToLayout(key, component) {
      this.layout[key].push(component);
      this.updateLayoutToState();
    },
    // merge this.layout with this.$store.state.layout.layout_tabs
    updateLayoutBasedOnState() {
      if (this.roles[0] === 'BootcampFall23') {
        this.layout = this.$store.state.layouts.bootcampView.layout_tabs;
        this.layout_selection = this.$store.state.layouts.bootcampView.layout_tab_selection;
      } else if (this.roles[0] === 'SignalSchool') {
        this.layout = this.$store.state.layouts.SignalSchoolView.layout_tabs;
        this.layout_selection = this.$store.state.layouts.SignalSchoolView.layout_tab_selection;
        this.$store.dispatch('quests/setQuestMode', true);
      } else {
        this.layout = this.$store.state.layouts.exploreView.layout_tabs;
        this.layout_selection = this.$store.state.layouts.exploreView.layout_tab_selection;
      }
    },
    updateLayoutToState() {
      this.$store.dispatch('updateLayoutSettings', {
        layout: this.layout,
        layout_selection: this.layout_selection,
      });
    },
    convertToKebabCase(string) {
      return string
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
    },
    getShortcut(label, _key) {
      return this.$fetchShortcut(label, _key);
    },
    componentsFilter() {
      if (this.compfilter.length > 0) {
        return this.componentsOptions.filter(
          (item) => item.options.title.toLowerCase().includes(this.compfilter)
            || item.options.title.toUpperCase().includes(this.compfilter)
            || item.options.title.includes(this.compfilter),
        );
      }
      return this.componentsOptions;
    },
  },

  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),

    roles() {
      return this.user.roles;
    },

    randomNumer() {
      return Math.random();
    },
    showMeta() {
      if (this.$store.state.wikiInformation) {
        const wikiInfoLength = this.$store.state.wikiInformation.length;
        if (wikiInfoLength > 0) {
          return true;
        }
      }
      if (
        this.$store.state.selectedNode
        && this.$store.state.selectedNode.nodeLabel
      ) {
        return true;
      }

      if (
        this.$store.state.wolframInformation
        && this.$store.state.wolframInformation.answer
      ) {
        return true;
      }
      return false;
    },
    componentsOptions() {
      return coreComponentOptions
        .filter((widget) => this.abilities.can('load', widget.name))
        .sort((a, b) => {
          if (a.options.title < b.options.title) return -1;
          return a.options.title > b.options.title ? 1 : 0;
        });
    },
    showExploreDescription() {
      return this.$store.state.explore.extract;
    },
    edit() {
      return this.$store.state.layouts.edit;
    },
    resize() {
      return this.$store.state.layouts.resize;
    },
  },
  mounted() {
    console.log(this.roles[0], ' is the primary role');
    this.treeHook = new Tree(this.tree);
    this.treeHook.init();
    const thoughtId = this.$route.params.id;
    if (thoughtId) {
      this.$store.dispatch('getSavedTjState', thoughtId);
    }
    // listen root events
    this.$root.$on('addPanel', (payload) => {
      if (payload === 'Home') {
        this.addPanel();
      }
    });
    this.updateLayoutBasedOnState();
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (
        mutation.type === 'SET_LAYOUT_SETTINGS'
        || mutation.type === 'SET_SAVED_TJ_STATE'
      ) {
        this.updateLayoutBasedOnState();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$root.$off('addPanel', () => {
      this.addPanel();
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/abstracts/variables";

// #split-layout-comp {
//   height: 105vh;
//   z-index: -5;
// }
.radio_for_active {
  // position: absolute;
  // top: -10px;
  // right: 0;
  // z-index: 999999;
  [type="radio"] {
    // position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .active_comp_class {
    cursor: pointer;
    margin: 0 0.2rem 0 0;
  }
}
.split-layout-custom-style {
  border-radius: 5px;
  overflow-x: hidden !important;
}

.nav-tabs {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.nav-tabs .nav-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 4px 4px 0 0;
  gap: 0.5em;

  &:focus,
  &:hover {
    background: #f2f2f2;
    cursor: pointer;
  }
}

.nav-link {
  color: rgba(0, 0, 0, 0.4);
}

.nav-tabs {
  border-bottom: 0;
}
.nav-item {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.tab-top {
  margin-bottom: 1em;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background-color: $white;
  z-index: 2;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.tab-content {
  display: flex;
  align-items: center;
}

.tab-active {
  .tab-content {
    color: $edvantaBlue;

    .tab-title {
      padding-bottom: 0.3em;
      border-bottom: 3px solid $edvantaYellow;
    }
  }
}

.custom-box-css {
  border-radius: 50%;
  transition: all ease-in-out 200ms;

  &:hover {
    cursor: pointer;
    background: rgba(154, 160, 166, 0.157);
  }
}

// .custom-help-css {
//   margin-top: 0.5%;
//   // height: 20px;
//   // width: 20px;
// }

.box-x {
  padding: 2px;
}

.component-menu {
  max-height: 12em;
  overflow: auto;
}
.feedback-notification {
  position: absolute;
  top: 46px;
  right: 15px;
  width: 23%;
  background: linear-gradient(135deg, #8e9eab, #eef2f3);
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}
</style>
